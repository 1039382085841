import {
  type Cart,
  type Category,
  type Payment,
  type Service,
  type Session,
  type User,
} from '@prisma/client'
import { getUserImgSrc } from '~/utils/misc.tsx'

interface Connectif {
  managed: ConnectifManaged
}

interface ConnectifManaged {
  sendEvents: (arg1: any, arg2?: any) => void
  isInitialized: any
}

export interface ConnectifEntityInfo {
  primaryKey: string
  _name: string
  _surname: string
  _birthdate: string
  // _newsletterSubscriptionStatus: string
}

declare global {
  var connectif: Connectif
  var connectifConfiguration: any
  var initializeConnectif: any
  interface Window {
    connectif: Connectif
  }
}

function sendEventOnInitialize(eventCallback: any) {
  // if (ENV.MODE !== EnvEnum.PRODUCTION && ENV.MODE !== EnvEnum.STAGING) return
  if (
    window.connectif &&
    window.connectif.managed &&
    window.connectif.managed.isInitialized()
  ) {
    eventCallback()
  } else {
    // initialize connectif and send event on initialized
    document.addEventListener('connectif.loaded', function onConnectifLoaded() {
      initializeConnectif(connectifConfiguration)
    })
    document.addEventListener(
      'connectif.managed.initialized',
      function onConnectifInitialized() {
        eventCallback()
      },
      { once: true },
    )
  }
}

export function sendEventPageVisit(entityInfo?: ConnectifEntityInfo) {
  sendEventOnInitialize(() => {
    window.connectif.managed.sendEvents([{ type: 'page-visit' }], {
      entityInfo,
    })
  })
}

type CheckoutInfo = {
  cartId: string
  service: Service & {
    category: Category
    professional: User & { avatarImage: { id?: string } }
  }
  reviews: Reviews
  baseUrl: string
  cuponTotalDiscount?: number
}
type Reviews = {
  stars: number
  reviews: any[]
}
export function sendEventCheckout(
  { cartId, service, reviews, baseUrl, cuponTotalDiscount = 0 }: CheckoutInfo,
  entityInfo?: ConnectifEntityInfo,
) {
  sendEventOnInitialize(() => {
    const professionalImage = `${baseUrl}${getUserImgSrc(
      service.professional.avatarImage?.id,
    )}`
    const cart = {
      cartId,
      totalQuantity: 1,
      totalPrice: service.price,
      products: [
        {
          quantity: 1,
          price: service.price,
          productDetailUrl: `${baseUrl}/${service.professional.slug}/${service.slug}`,
          productId: service.id.toString(),
          name: service.name,
          description: service.description,
          imageUrl: professionalImage,
          unitPrice: service.price,
          availability: 'InStock',
          categories: [service.category.name],
          tags: [service.professional.nickname],
          brand: 'Miistico',
          reviewCount: reviews.reviews.length,
          rating: reviews.stars,
          ratingCount: reviews.reviews.length,
          thumbnailUrl: professionalImage,
          unitPriceOriginal: service.price,
          unitPriceWithoutVAT: service.price - service.price * 0.21,
          discountedAmount: cuponTotalDiscount,
          discountedPercentage: (cuponTotalDiscount / service.price) * 100,
          publishedAt: service.createdAt,
        },
      ],
    }
    window.connectif.managed.sendEvents([{ type: 'page-visit' }], {
      pageInfo: { tags: ['checkout'] },
      entityInfo,
      cart,
    })
  })
}

export async function sendEventNewRegister(entityInfo?: ConnectifEntityInfo) {
  sendEventOnInitialize(() => {
    window.connectif.managed.sendEvents([{ type: 'register' }], { entityInfo })
  })
}

export async function sendEventSubscriptionNewsLetter(
  email: string,
  entityInfo?: ConnectifEntityInfo,
) {
  sendEventOnInitialize(() => {
    window.connectif.managed.sendEvents(
      [{ type: 'newsletter-subscribe', email }],
      { entityInfo },
    )
  })
}

export function sendEventCategory(
  category: string,
  entityInfo?: ConnectifEntityInfo,
) {
  sendEventOnInitialize(() => {
    window.connectif.managed.sendEvents([{ type: 'page-visit' }], {
      pageInfo: { categories: [category], entityInfo },
    })
  })
}

export function sendEventPurchase(
  payment: Payment & {
    cart: Cart | null
    session: Session & {
      service: Service & {
        professional: User & { avatarImage: { id?: string } }
        category: Category
      }
    }
  },
  baseUrl: string,
  entityInfo?: ConnectifEntityInfo,
) {
  sendEventOnInitialize(() => {
    window.connectif.managed.sendEvents(
      [
        {
          type: 'purchase',
          purchase: {
            cartId: payment?.cart?.id,
            totalQuantity: 1,
            totalPrice: payment.price,
            products: [
              {
                name: payment.session.service.name,
                productDetailUrl: `${baseUrl}/${payment.session.service.professional.slug}/${payment.session.service.slug}`,
                productId: payment.session.serviceId.toString(),
                unitPrice: payment.session.service.price,
                imageUrl: `${baseUrl}${getUserImgSrc(
                  payment.session.service.professional.avatarImage?.id,
                )}`,
                description: payment.session.service.description,
                tags: [
                  payment.session.service.professional.nickname,
                  payment.session.service.category.name,
                ],
                publishedAt: payment.session.service.createdAt,
                quantity: 1,
                price: payment.session.service.price,
              },
            ],
            purchaseId: payment.id.toString(),
            purchaseDate: payment.createdAt,
            paymentMethod: payment.method,
          },
        },
      ],
      { entityInfo },
    )
  })
}

export function sendEventProductVisited(
  service: Service & {
    professional: User & { avatarImage: { id?: string } }
    category: Category
  },
  baseUrl: string,
  entityInfo?: ConnectifEntityInfo,
) {
  sendEventOnInitialize(() => {
    window.connectif.managed.sendEvents(
      [
        {
          type: 'product-visited',
          product: {
            name: service.name,
            productDetailUrl: `${baseUrl}/${service.professional.slug}/${service.slug}`,
            productId: service.id.toString(),
            unitPrice: service.price,
            imageUrl: `${baseUrl}${getUserImgSrc(
              service.professional.avatarImage?.id,
            )}`,
            description: service.description,
            tags: [service.professional.nickname, service.category.name],
            publishedAt: service.createdAt,
            quantity: 1,
            price: service.price,
          },
        },
      ],
      { entityInfo },
    )
  })
}
