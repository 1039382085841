import { useLocation } from '@remix-run/react'
import {
  useContext,
  createContext,
  useEffect,
  type ReactNode,
  useState,
} from 'react'
import {
  type ConnectifEntityInfo,
  sendEventNewRegister,
  sendEventPageVisit,
} from './connectif-events.client.ts'
import { type User } from '@prisma/client'

type ConnectifContextProps = {
  entityInfo?: ConnectifEntityInfo
  cartId?: string
  setCartId: (cartId?: string) => void
  setSignup: (value: boolean) => void
  // lo que devolvemos
}
export const ConnectifContext = createContext<ConnectifContextProps>({} as any)

export const useConnectifContext = () => {
  const context = useContext(ConnectifContext)
  if (!context) {
    throw new Error(
      'useConnectifContext must be rendered within the SocketProvider',
    )
  }
  return context
}

interface UserInfo {
  id: User['id']
  firstName: User['firstName']
  lastName: User['lastName']
  birthDate: User['birthDate']
  email: User['email']
}

export const ConnectifProvider = ({
  children,
  userInfo,
}: {
  children: ReactNode
  userInfo: UserInfo
}) => {
  const [isSignup, setSignup] = useState(false)
  const [cartId, setCartId] = useState<string | undefined>(undefined)
  const location = useLocation()
  const entityInfo = userInfo
    ? {
        _name: userInfo.firstName,
        _surname: userInfo.lastName,
        _birthdate: new Date(userInfo.birthDate).toISOString(),
        primaryKey: userInfo.email,
      }
    : undefined

  useEffect(() => {
    if (typeof window !== 'undefined') {
      sendEventPageVisit(entityInfo)
    }
    if (isSignup) {
      sendEventNewRegister(entityInfo)
      setSignup(false)
    }
  }, [location])

  return (
    <ConnectifContext.Provider
      value={{
        entityInfo,
        cartId,
        setCartId,
        setSignup,
      }}
    >
      {children}
    </ConnectifContext.Provider>
  )
}
